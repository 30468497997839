<template>
  <div class="row card-container">
    <div class="col">
      <form class="form card card-body responsive">
        <h1>{{ $t("login.default.loginHeader") }}</h1>
        <div class="form-group mx-auto">
          <label for="inputEmail">{{ $t("login.default.labelEmail") }}</label>

          <input id="inputEmail" v-model="email" class="form-control mt-2" placeholder="" required type="email">
          <div v-show="errors['email']" id="err_inputEmail" class="form-error">{{ errors['email'] }}</div>
        </div>
        <div class="form-group mx-auto mt-2">
          <label for="inputPassword">{{ $t("login.default.labelPassword") }}</label>
          <input id="inputPassword" v-model="password" class="form-control mt-2" placeholder="" required
                 type="password">
          <div v-show="errors['password']" id="err_inputPassword" class="form-error">{{ errors['password'] }}</div>
        </div>
        <div v-show="isFirstLogin" class="form-group mx-auto mt-2">
          <label for="dob">{{ $t("login.default.labelBirthday") }}</label>
        </div>
        <div v-show="isFirstLogin" class="row dob-section">
          <div class="d-inline-flex">
            <div class="input-group custom-select-year parent-block">
              <select id="birthdayYear" v-model="year" class="form-control select_year form-select">
                <option v-for="year in years"
                        :value="year">{{ year }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block">
              <select id="birthdayMonth" v-model="month" class="form-control select_month form-select">
                <option v-for="month in months"
                        :value="month">{{ month }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block m-r-0">
              <select id="birthdayDate" v-model="date" class="form-control select_date form-select">
                <option v-for="day in days"
                        :value="day">{{ day }}
                </option>
              </select>
            </div>
          </div>
          <div v-show="errors['birthday']" id="err_inputBirth" class="form-error">{{ errors['birthday'] }}</div>
        </div>
        <div class="form-check mx-auto">
          <input id="flexCheckDefault" v-model="isRemember" class="form-check-input" type="checkbox">
          <label class="form-check-label" for="flexCheckDefault">
            {{ $t("login.default.rememberMe") }}
          </label>
        </div>

        <button class="btn btn-primary font-weight-bold mx-auto m-t-0" type="button" v-on:click="doLogin">
          {{ $t("login.default.loginButton") }}
        </button>
        <router-link :to="{ name: 'ResetPassword' }" class="text-center m-t-20 forgot-password ashen-link">
          {{ $t("login.default.forgotPassword") }}
        </router-link>

        <router-link v-show="this.tournamentKey != null" :to="{ name: 'RegisterUser', params: {'key': this.tournamentKey} }" class="text-center m-t-40 forgot-password ashen-link">
          {{ $t("login.default.createNewUser") }}
        </router-link>
      </form>
    </div>
  </div>

</template>

<script>
import {mapActions} from 'vuex'
import Common from '../assets/jsipclient/common'
import {Credentials} from '../assets/jsipclient/index'
import Helper from "../assets/jsipclient/helper"

import {Login} from '../store'

export default {
  name: "LoginWithDob",
  data() {
    return {
      response: {},
      loading: true,
      loginError: false,
      email: '',
      password: '',
      years: Helper.getRange(1920, (new Date).getFullYear(), 4),
      months: Helper.getRange(1, 12),
      days: Helper.getRange(1, 31),
      year: '1990',
      month: '01',
      date: '01',
      isRemember: false,
      errors: [],
      isFirstLogin: true,
      tournamentKey: null,
    }
  },
  methods: {
    ...mapActions(['Login']),
    async doLogin() {
      this.errors = [];
      try {
        const api = Common.createLoginApi()
        var params = new Credentials()
        params.email = this.email
        params.password = this.password
        if (this.isFirstLogin) {
          params.birthday = this.year + "/" + this.month + "/" + this.date
        } else {
          params.birthday = null
        }
        params.remember = this.isRemember
        params.tournamentKey = this.tournamentKey

        const jsonObject = await this.Login(params)
        // save cookie
        Common.saveFirstLogin()
        // is first login
        const isNeedSetup = jsonObject.user.requireUserInfo

        if (isNeedSetup) {
          this.$router.push({name: 'InitialLoginFirst'})
        } else {
          this.$router.push({name: 'Home'})
        }

      } catch (error) {
        const isLogin = Common.checkFirstLogin(error.response)
        if (isLogin) {
          const message = this.$t("login.default.toastErrorFTSettings")
          Common.showToast(message, true)
          this.isFirstLogin = true
          this.year = "1990"
          this.month = "01"
          this.date = "01"
        }
        Common.getErrorList(error.response, this.errors)
        Common.handleAPIError(error)
      }
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.isFirstLogin = Common.isFirstLogin()

    this.tournamentKey = this.$route.query.t
    console.log("this.tournamentKey=" + this.tournamentKey)
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 800px) {
  .card-container {
    margin-bottom: 85px !important;
  }
}
</style>
